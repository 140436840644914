define("@manager-web/grids/components/grid/card/attributes", ["exports", "@ember/component", "@ember/component/template-only", "@ember/template-factory"], function (_exports, _component, _templateOnly, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="columns-1 gap-8 md:columns-2 md:gap-4 lg:columns-3 xl:columns-4">
    {{yield}}
  </div>
  */
  {
    "id": "CMdeHanw",
    "block": "[[[10,0],[14,0,\"columns-1 gap-8 md:columns-2 md:gap-4 lg:columns-3 xl:columns-4\"],[12],[1,\"\\n  \"],[18,1,null],[1,\"\\n\"],[13]],[\"&default\"],false,[\"div\",\"yield\"]]",
    "moduleName": "@manager-web/grids/components/grid/card/attributes.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});